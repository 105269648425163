import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import StandaloneCarCard from "@common/StandaloneCarCard";
import { navigate, graphql } from "gatsby";

import { Breadcrumbs } from "@common/Breadcrumbs";
import {
  Button,
  Radio,
  RadioGroup,
  OptionFormControlLabel,
  FormControlLabel,
} from "@material-ui/core";

import Footer from "@sections/Footer";
import { Container } from "@components/global";
import { calculateRental } from "./../../calc";

const NextButton = styled(({ className, ...rest }) => {
  return (
    <div className={className}>
      <Button {...rest} />
    </div>
  );
})`
  text-align: center;
  margin-top: 1rem;
`;

export const Header = styled.h1`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.larger};
  margin: 1rem 0;
`;

export const Options = styled.div`
  grid-area: options;
  margin-bottom: 2.5rem;
  li {
    color: ${(props) => props.theme.color.black.light};
  }
  p {
    ${(props) => props.theme.font_size.small};
    color: ${(props) => props.theme.color.black.light};
  }
`;

export const CarCard = styled.div`
  grid-area: carCard;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "options"
    "carCard";
  @media (min-width: 960px) {
    grid-template-areas: "options carCard";
    align-items: flex-start;
    justify-items: center;
    grid-gap: 2rem;
  }
  margin-bottom: 3rem;
`;

const ReservationInsurancePage = ({ data, location }) => {
  useEffect(() => {
    if (
      (typeof window !== "undefined" && !location.state) ||
      !location.state ||
      !location.state.car
    ) {
      window.location.href = "/";
    }
  });
  const [insurance, setInsurance] = useState(
    location.state && location.state.insurance !== undefined
      ? location.state.insurance
      : true
  );
  if (
    (typeof window !== "undefined" && !location.state) ||
    !location.state ||
    !location.state.car
  ) {
    return null;
  }
  const rentalState = location.state;
  if (rentalState.segment.insurance === -1) {
    const state = {
      ...location.state,
      insurance: false,
    };
    navigate("/rezerwacja/udogodnienia", {
      state,
    });
  }
  const calc = calculateRental(
    rentalState.odbiorDate,
    rentalState.zwrotDate,
    rentalState.segment,
    rentalState.car,
    rentalState.odbiorOffice,
    rentalState.zwrotOffice,
    rentalState.options || [],
    insurance
  );
  return (
    <Layout>
      <TopHeader />
      <Container>
        <Breadcrumbs
          miniElement={{
            href: `/rezerwacja/samochod`,
            label: "Wybór samochodu",
            state: location.state,
          }}
          path={[
            {
              href: `/`,
              label: "Rezerwacja",
              state: location.state,
            },
            {
              href: `/rezerwacja/samochod`,
              label: "Wybór samochodu",
              state: location.state,
            },
            {
              href: `/rezerwacja/ubezpieczenie`,
              label: "Ubezpieczenie",
              state: location.state,
              current: true,
            },
            {
              href: `/rezerwacja/udogodnienia`,
              label: "Udogodnienia",
              state: location.state,
              disabled: true,
            },
            ,
            {
              href: `/rezerwacja/podsumowanie`,
              label: "Podsumowanie",
              state: location.state,
              disabled: true,
            },
          ]}
        />
        <Wrapper>
          <Options>
            <Header>Krok 3 - Ubezpieczenie lub kaucja</Header>

            <RadioGroup
              value={insurance ? "insurance" : "deposit"}
              onChange={(ev, val) => {
                const checked = val === "insurance";
                setInsurance(checked);
              }}
            >
              <FormControlLabel
                value="deposit"
                control={<Radio color="primary" />}
                label={
                  <>
                    <b>Kaucja</b>
                  </>
                }
              />
              <p>
                <b style={{ color: "#222" }}>
                  Kierowca ponosi pełną odpowiedzialność za wypożyczony
                  samochód, szkody umyślne i nieumyślne na wynajętym aucie.
                </b>
              </p>
              <ul>
                <li>{calc.deposit} zł kaucji</li>
                <li>brak zwrotu kaucji w przypadku uszkodzenia pojazdu</li>
                <li>
                  brak ubezpieczenia i zniesienia udziału własnego w szkodzie
                </li>
              </ul>
              <FormControlLabel
                value="insurance"
                control={<Radio color="primary" />}
                label={
                  <>
                    <b>Ubezpieczenie dodatkowe</b>
                  </>
                }
              />
              <p>
                <b style={{ color: "#222" }}>
                  Zwalnia kierowcę z odpowiedzialności za nieumyślne szkody na
                  pojeździe z jego winy, m.in:
                </b>
              </p>
              <ul>
                <li>uszkodzenia pojazdu w przypadku kolizji</li>
                <li>
                  zarysowań karoserii, podwozia i dachu, uszkodzenia opon i szyb
                </li>
                <li>
                  koszt pełnego ubezpieczenia za {calc.numDays}{" "}
                  {calc.numDays === 1 ? "dzień" : "dni"} wynajmu to tylko{" "}
                  {calc.insurancePeriodPrice} zł
                </li>
              </ul>
            </RadioGroup>

            <NextButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                const state = {
                  ...location.state,
                  insurance,
                };
                navigate("/rezerwacja/udogodnienia", {
                  state,
                });
              }}
            >
              Dalej
            </NextButton>
          </Options>
          <CarCard>
            <StandaloneCarCard
              hideButtons
              hidePrices
              noHoverEffect
              showCosts
              state={{
                ...location.state,
                insurance,
              }}
            />
          </CarCard>
        </Wrapper>
      </Container>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ReservationInsurancePageQuery {
    site {
      siteMetadata {
        options {
          name
          price
          multiplyByDays
        }
        offices {
          name
          workingHoursMonFri {
            start
            end
          }
          workingHoursSaturday {
            start
            end
          }
          deliveryFee
          returnFee
          deliveryFeeOutsideWorkingHours
          returnFeeOutsideWorkingHours
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default ReservationInsurancePage;
